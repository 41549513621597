import "core-js";
import { configure } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Toast } from "saltui";
import PrivateRoute from "../components/PrivateRoute";
import settings from "../components/Settings";
import "../images/svgSymbols.js";
import "./app.styl";
import Store from "./appStore";

import dtClientSVC from "../components/DingtalkClientSVC";
import ErrorHandler from "../components/ErrorHandler";
import Settings from "../components/SettingInfo";
import TenantInfo from "../components/TenantInfo";
import UserInfo from "../components/UserInfo";
import Util from "../components/Util";
import PageAdmin from "../pages/admin/adminhome";
import PageChangeDashboardUserPwd from "../pages/admin/changedashboarduserpwd";
import PageEditMachinerie from "../pages/admin/machinery/edit";
import PageMachineryList from "../pages/admin/machinery/list";
import PageEditPackagingMethod from "../pages/admin/packagingmethod/edit";
import PagePackagingMethodlist from "../pages/admin/packagingmethod/list";
import PageEditProcessing from "../pages/admin/processing/edit";
import PageProcessingList from "../pages/admin/processing/list";
import PageEditQuarry from "../pages/admin/quarry/edit";
import PageQuarryList from "../pages/admin/quarry/list";
import PageEditSawBladeManufacturer from "../pages/admin/sawblademanufacturer/edit";
import PageSawBladeManufacturerList from "../pages/admin/sawblademanufacturer/list";
import PageEditStockingArea from "../pages/admin/stockingarea/edit";
import PageStockingAreaList from "../pages/admin/stockingarea/list";
import PageEditStoneCategory from "../pages/admin/stonecategory/edit";
import PageStoneCategoryList from "../pages/admin/stonecategory/list";
import PageEditStoneGrade from "../pages/admin/stonegrade/edit";
import PageStoneGradeList from "../pages/admin/stonegrade/list";
import PageAdminSyncUserAuth from "../pages/admin/syncuserauth";
import PageEditTag from "../pages/admin/tag/edit";
import PageTagList from "../pages/admin/tag/list";
import PageUpdateStoneArtifactItemUnitPrice from "../pages/admin/updatestoneartifactitemunitprice";
import PageEditUser from "../pages/admin/user/edit";
import PageUserList from "../pages/admin/user/list";
import PageEditBlock from "../pages/blocks/edit";
import PageBlockInfo from "../pages/blocks/info";
import PageBlockView from "../pages/blocks/list";
import PageEditCustomerGrade from "../pages/customers/customergrade/edit";
import PageCustomerGradeList from "../pages/customers/customergrade/list";
import PageCustomerHome from "../pages/customers/customerhome";
import PageEditCustomerType from "../pages/customers/customertype/edit";
import PageCustomerTypeList from "../pages/customers/customertype/list";
import PageEditDI from "../pages/customers/depositIncurred/edit";
import PageDIInfo from "../pages/customers/depositIncurred/info";
import PageEditCustomer from "../pages/customers/edit";
import PageEditFollowUpRecord from "../pages/customers/followuprecord/edit";
import PageFollowUpRecordInfo from "../pages/customers/followuprecord/info";
import PageCustomerInfo from "../pages/customers/info";
import PageEditInvoiceInfo from "../pages/customers/invoice/edit";
import PageInvoiceInfo from "../pages/customers/invoice/info";
import PageCustomerList from "../pages/customers/list";
import PageEditShippingAddress from "../pages/customers/shippingaddress/edit";
import PageShippingAddressInfo from "../pages/customers/shippingaddress/info";
import PageDevLogin from "../pages/devlogin";
import PageHome from "../pages/home";
import PagePOApproval from "../pages/paymentorders/approval";
import PagePOEdit from "../pages/paymentorders/edit";
import PagePOInfo from "../pages/paymentorders/info";
import PagePOItemSelector from "../pages/paymentorders/itemselector";
import PagePOList from "../pages/paymentorders/list";
import PageProductList from "../pages/products/productlist";
import PageEditSlab from "../pages/products/slab/edit";
import PageSlabInfo from "../pages/products/slab/info";
import PageStockInProduct from "../pages/products/stockin";
import PageEditBundle from "../pages/products/stonebundle/edit";
import PageStoneBundleInfo from "../pages/products/stonebundle/info";
import PageBundleViewForScan from "../pages/products/stonebundle/scan";
import PageTileInformation from "../pages/products/tile/info";
import PageTilePackingCaseInformation from "../pages/products/tilepackingcase/info";
import PageReportBlockInventory from "../pages/reports/blockinventory";
import PageReportBlocks from "../pages/reports/blockstockin";
import PageReportManufacturing from "../pages/reports/manufacturing";
import PageReportPayment from "../pages/reports/payment";
import PageReportPolishedSlabInventory from "../pages/reports/polishedslabinventory";
import PageReports from "../pages/reports/reporthome";
import PageReportSales from "../pages/reports/sales";
import PageAddMaterialSpecForSalesOrder from "../pages/salesorders/addmaterialspecforsalesorder";
import PageAddProcessingSpecsForTile from "../pages/salesorders/addprocessingspecfortile";
import PageSOApproval from "../pages/salesorders/approval"; // 销售订单审批页面
import PageBundleSelectorForScan from "../pages/salesorders/bundleselectorforscan";
import PageSOEdit from "../pages/salesorders/edit";
import PageSalesOrderInformation from "../pages/salesorders/info";
import PageSOList from "../pages/salesorders/list";
import PageSOBundleSelector from "../pages/salesorders/salesorderbundleselector";
import PageSOType from "../pages/salesorders/salesordertype";
import PageUpdateSODPrice from "../pages/salesorders/updatesodprice";
import PageAppliedPriceItemSelector from "../pages/shared/appliedpriceitemselector";
import PageApproverView from "../pages/shared/approval"; // 供审批人查看的审批列表列表，包括销售订单、领料单、退料单、调拨单，有待审批和已经审批两个Tab
import PageBlockSelector from "../pages/shared/blockselector";
import PageCustomerSelector from "../pages/shared/customerselector";
import PagePriceItemEditor from "../pages/shared/editpriceitem";
import PageEditUntrackedContent from "../pages/shared/edituntrackedcontent";
import PageTrimmingBlockSelector from "../pages/shared/trimmingblockselector";
import PageTrimmingData from "../pages/shared/trimmingdata";
import PageTrimmingRecordApproval from "../pages/shared/trimmingrecords/approval";
import PageTrimmingRecordInfo from "../pages/shared/trimmingrecords/info";
import PageEditShippingOrder from "../pages/shippingorders/edit";
import PageShippingOrderInfo from "../pages/shippingorders/info";
import PageShippingOrderItemSelector from "../pages/shippingorders/itemselector";
import PageShippingOrderList from "../pages/shippingorders/list";
import PageSlabCheckOutRequestApproval from "../pages/slabcheckoutrequests/approval";
import PageSlabCheckOutRequest from "../pages/slabcheckoutrequests/edit";
import PageSlabCheckOutRequestInformation from "../pages/slabcheckoutrequests/info";
import PageSlabCheckOutRequestSelector from "../pages/slabcheckoutrequests/itemselector";
import PageSlabReturnRequestApproval from "../pages/slabreturnrequests/approval";
import PageSlabReturnRequestApprovalDetail from "../pages/slabreturnrequests/approvaldetail";
import PageSlabReturnRequest from "../pages/slabreturnrequests/edit";
import PageSlabReturnRequestInformation from "../pages/slabreturnrequests/info";
import PageSlabReturnRequestSelector from "../pages/slabreturnrequests/itemselector";
import PageSysInfo from "../pages/sysinfo";
import PageTOApproval from "../pages/transferorder/approval";
import PageEditTO from "../pages/transferorder/edit";
import PageTOInfo from "../pages/transferorder/info";
import PageTOItemSelector from "../pages/transferorder/itemselector";
import PageTOList from "../pages/transferorder/list";
import PageUntrackedProductSheetEditor from "../pages/untrackedproductsheets/edit";
import PageUntrackedProductSheetInfo from "../pages/untrackedproductsheets/info";
import PageWODetermineMP from "../pages/workorders/determinemp";
import PageWOEdit from "../pages/workorders/edit";
import PageWOFillingData from "../pages/workorders/fillingdata";
import PageWOFillingQA from "../pages/workorders/fillingqa";
import PageWOInfo from "../pages/workorders/info";
import PageWOList from "../pages/workorders/list";
import PageWOMaterialReq from "../pages/workorders/materialreq";
import PageWOMaterialReqApproval from "../pages/workorders/materialreqapproval";
import PageWOMSSelector from "../pages/workorders/materialspecselector";
import PageWOPolishingData from "../pages/workorders/polishingdata";
import PageWOPolishingQA from "../pages/workorders/polishingqa";
import PageWOSawingData from "../pages/workorders/sawingdata";
import PageWOSawingQA from "../pages/workorders/sawingqa";
import PageWOTrimmingQA from "../pages/workorders/trimmingqa";
import PageEditPlanningRecord from "../pages/blocks/planningrecord/PageEditPlanningRecord";
import PageSGCRApproval from "../pages/slabgradechangerequests/approval/PageSGCRApproval";
import PageSGCRInfo from "../pages/slabgradechangerequests/info/PageSGCRInfo";
import PageSGCREdit from "../pages/slabgradechangerequests/edit/PageEditSGCR";
import PageSGCRItemSelector from "../pages/slabgradechangerequests/itemselector/PageSGCRItemSelector";
import PageSGCRList from "../pages/slabgradechangerequests/list/PageSGCRList";
import { IDingtalkConfigDTO, ILoginResultDTO } from "./WebAPIClients";
import PageReportSalesPerf from "../pages/reports/salesperformance/PageReportSalesPerf";

declare interface IAppState {
  tenantAbbrCode: string;
}
@observer
class App extends React.Component<{}, IAppState> {
  private isDDReady: boolean;
  private ddAuthCode: string | null;
  constructor(props) {
    super(props);
    this.state = {
      tenantAbbrCode: Util.GetUrlParam(window.location.href, "AC"),
    };
    this.isDDReady = false;
    this.ddAuthCode = null;
  }

  public async componentDidMount() {
    await this.ensureLogin();
  }

  public render() {
    let borderStyle = "3px solid ";
    switch (settings.environment) {
      case "development":
        borderStyle += "#9254de";
        break;
      case "testing":
        borderStyle += "#ff4d4f";
        break;
      case "staging":
        borderStyle += "#40a9ff";
        break;
      default:
        borderStyle = "";
    }

    const t = this;
    const loggedIn = t.isLoggedIn();
    const hasAnyPermission = t.hasAnyPermission();

    if (
      (loggedIn === false || hasAnyPermission === false) &&
      !t.isDevLoginPage()
    ) {
      let msg = "";
      if (loggedIn === false) {
        msg = "正在进行钉钉登录...，此功能仅能在钉钉中使用";
      } else if (hasAnyPermission === false) {
        msg = "您的账号没有对应的系统角色，请联系管理员分配角色后再试";
      }

      return <div className="login">{msg}</div>;
    }

    return (
      <div style={{ borderTop: borderStyle }}>
        <BrowserRouter>
          <Switch>
            <PrivateRoute exact={true} path="/" component={PageHome} />
            <Route path="/devlogin" component={PageDevLogin} />
            <PrivateRoute path="/blocks/edit/:id" component={PageEditBlock} />
            <PrivateRoute path="/blocks/info/:id" component={PageBlockInfo} />
            <PrivateRoute path="/blocks/new" component={PageEditBlock} />
            <PrivateRoute
              path="/blocks/trimmingdata"
              component={PageTrimmingData}
            />
            <PrivateRoute
              path="/trimmingdata/edit/:id"
              component={PageTrimmingData}
            />
            <PrivateRoute
              path="/planningrecord/edit/:id"
              component={PageEditPlanningRecord}
            />
            <PrivateRoute path="/blocks" component={PageBlockView} />
            <PrivateRoute
              path="/admin/stonecategory/edit/:id"
              component={PageEditStoneCategory}
            />
            <PrivateRoute
              path="/admin/stonecategory/new"
              component={PageEditStoneCategory}
            />
            <PrivateRoute
              path="/admin/stonecategory"
              component={PageStoneCategoryList}
            />
            <PrivateRoute
              path="/admin/stonegrade/edit/:id"
              component={PageEditStoneGrade}
            />
            <PrivateRoute
              path="/admin/stonegrade/new"
              component={PageEditStoneGrade}
            />
            <PrivateRoute
              path="/admin/stonegrade"
              component={PageStoneGradeList}
            />
            <PrivateRoute
              path="/admin/processing/edit/:id"
              component={PageEditProcessing}
            />
            <PrivateRoute
              path="/admin/processing/new"
              component={PageEditProcessing}
            />
            <PrivateRoute
              path="/admin/processing"
              component={PageProcessingList}
            />
            <PrivateRoute
              path="/admin/quarry/edit/:id"
              component={PageEditQuarry}
            />
            <PrivateRoute path="/admin/quarry/new" component={PageEditQuarry} />
            <PrivateRoute path="/admin/quarry" component={PageQuarryList} />
            <PrivateRoute
              path="/admin/stockingarea/edit/:id"
              component={PageEditStockingArea}
            />
            <PrivateRoute
              path="/admin/stockingarea/new"
              component={PageEditStockingArea}
            />
            <PrivateRoute
              path="/admin/stockingarea"
              component={PageStockingAreaList}
            />
            <PrivateRoute
              path="/admin/machinery/edit/:id"
              component={PageEditMachinerie}
            />
            <PrivateRoute
              path="/admin/machinery/new"
              component={PageEditMachinerie}
            />
            <PrivateRoute
              path="/admin/machinery"
              component={PageMachineryList}
            />
            <PrivateRoute
              path="/admin/sawblademanufacturer/edit/:id"
              component={PageEditSawBladeManufacturer}
            />
            <PrivateRoute
              path="/admin/sawblademanufacturer/new"
              component={PageEditSawBladeManufacturer}
            />
            <PrivateRoute
              path="/admin/sawblademanufacturer"
              component={PageSawBladeManufacturerList}
            />
            <PrivateRoute
              path="/admin/packagingmethod/edit/:id"
              component={PageEditPackagingMethod}
            />
            <PrivateRoute
              path="/admin/packagingmethod/new"
              component={PageEditPackagingMethod}
            />
            <PrivateRoute
              path="/admin/packagingmethod"
              component={PagePackagingMethodlist}
            />
            <PrivateRoute
              path="/admin/changedashboarduserpwd"
              component={PageChangeDashboardUserPwd}
            />
            <PrivateRoute
              path="/admin/syncuserauth"
              component={PageAdminSyncUserAuth}
            />
            <PrivateRoute
              path="/admin/updatestoneartifactitemunitprice"
              component={PageUpdateStoneArtifactItemUnitPrice}
            />
            <PrivateRoute
              path="/admin/user/edit/:id"
              component={PageEditUser}
            />
            <PrivateRoute path="/admin/user" component={PageUserList} />
            <PrivateRoute path="/admin/tag/edit/:id" component={PageEditTag} />
            <PrivateRoute path="/admin/tag/new" component={PageEditTag} />
            <PrivateRoute path="/admin/tag" component={PageTagList} />
            <PrivateRoute path="/admin" component={PageAdmin} />
            <PrivateRoute
              path="/salesorders/info/:id"
              component={PageSalesOrderInformation}
            />
            <PrivateRoute path="/salesorders/edit/:id" component={PageSOEdit} />
            <PrivateRoute
              path="/salesorders/approval/:id"
              component={PageSOApproval}
            />
            <PrivateRoute
              path="/salesorders/priceitemeditor"
              component={PagePriceItemEditor}
            />
            <PrivateRoute
              path="/salesorders/untrackedcontenteditor"
              component={PageEditUntrackedContent}
            />
            <PrivateRoute
              path="/salesorders/addmaterialspecforsalesorder"
              component={PageAddMaterialSpecForSalesOrder}
            />
            <PrivateRoute
              path="/salesorders/typeselector"
              component={PageSOType}
            />
            <PrivateRoute
              path="/salesorders/bundleselector"
              component={PageSOBundleSelector}
            />
            <PrivateRoute
              path="/salesorders/bundleselectorforscan"
              component={PageBundleSelectorForScan}
            />
            <PrivateRoute
              path="/salesorders/bundleviewforscan"
              component={PageBundleViewForScan}
            />
            <PrivateRoute
              path="/salesorders/addprocessingspecs"
              component={PageAddProcessingSpecsForTile}
            />
            <PrivateRoute path="/salesorders/new" component={PageSOEdit} />
            <PrivateRoute
              path="/salesorders/updatesalesorderdetailprice"
              component={PageUpdateSODPrice}
            />
            <PrivateRoute path="/salesorders" component={PageSOList} />
            <PrivateRoute
              path="/slabcheckoutrequests/info/:id"
              component={PageSlabCheckOutRequestInformation}
            />
            <PrivateRoute
              path="/slabcheckoutrequests/approval/:id"
              component={PageSlabCheckOutRequestApproval}
            />
            <PrivateRoute
              path="/slabcheckoutrequests/itemselector"
              component={PageSlabCheckOutRequestSelector}
            />
            <PrivateRoute
              path="/slabcheckoutrequests/new"
              component={PageSlabCheckOutRequest}
            />
            <PrivateRoute
              path="/slabcheckoutrequests/untrackedcontenteditor"
              component={PageEditUntrackedContent}
            />
            <PrivateRoute
              path="/slabreturnrequests/info/:id"
              component={PageSlabReturnRequestInformation}
            />
            <PrivateRoute
              path="/slabreturnrequests/approval/:id"
              component={PageSlabReturnRequestApproval}
            />
            <PrivateRoute
              path="/slabreturnrequests/approvaldetail/:id"
              component={PageSlabReturnRequestApprovalDetail}
            />
            <PrivateRoute
              path="/slabreturnrequests/itemselector"
              component={PageSlabReturnRequestSelector}
            />
            <PrivateRoute
              path="/slabreturnrequests/new"
              component={PageSlabReturnRequest}
            />
            <PrivateRoute
              path="/slabreturnrequests/untrackedcontenteditor"
              component={PageEditUntrackedContent}
            />
            <PrivateRoute path="/workorders/info/:id" component={PageWOInfo} />
            <PrivateRoute
              path="/workorders/materialreq"
              component={PageWOMaterialReq}
            />
            <PrivateRoute
              path="/workorders/materialreqapproval"
              component={PageWOMaterialReqApproval}
            />
            <PrivateRoute
              path="/workorders/trimmingdata"
              component={PageTrimmingData}
            />
            <PrivateRoute
              path="/workorders/trimmingqa"
              component={PageWOTrimmingQA}
            />
            <PrivateRoute
              path="/workorders/materialspecselector"
              component={PageWOMSSelector}
            />
            <PrivateRoute
              path="/workorders/sawingdata"
              component={PageWOSawingData}
            />
            <PrivateRoute
              path="/workorders/determinemp"
              component={PageWODetermineMP}
            />
            <PrivateRoute
              path="/workorders/fillingdata"
              component={PageWOFillingData}
            />
            <PrivateRoute
              path="/workorders/polishingdata"
              component={PageWOPolishingData}
            />
            <PrivateRoute
              path="/workorders/sawingqa"
              component={PageWOSawingQA}
            />
            <PrivateRoute
              path="/workorders/fillingqa"
              component={PageWOFillingQA}
            />
            <PrivateRoute
              path="/workorders/polishingqa"
              component={PageWOPolishingQA}
            />
            <PrivateRoute path="/workorders/new" component={PageWOEdit} />
            <PrivateRoute path="/workorders" component={PageWOList} />
            <PrivateRoute
              path="/products/slab/info/:id"
              component={PageSlabInfo}
            />
            <PrivateRoute
              path="/products/slab/edit/:id"
              component={PageEditSlab}
            />
            <PrivateRoute
              path="/products/bundle/info/:id"
              component={PageStoneBundleInfo}
            />
            <PrivateRoute
              path="/products/bundle/edit/:id"
              component={PageEditBundle}
            />
            <PrivateRoute
              path="/products/tilepackingcase/info/:id"
              component={PageTilePackingCaseInformation}
            />
            <PrivateRoute
              path="/products/tile/info/:id"
              component={PageTileInformation}
            />
            <PrivateRoute
              path="/products/stockin"
              component={PageStockInProduct}
            />
            <PrivateRoute path="/products" component={PageProductList} />
            <PrivateRoute
              path="/shippingorders/edit/:id"
              component={PageEditShippingOrder}
            />
            <PrivateRoute
              path="/shippingorders/info/:id"
              component={PageShippingOrderInfo}
            />
            <PrivateRoute
              path="/shippingorders/itemselector"
              component={PageShippingOrderItemSelector}
            />
            <PrivateRoute
              path="/shippingorders/priceitemeditor"
              component={PagePriceItemEditor}
            />
            <PrivateRoute
              path="/shippingorders/appliedpriceitemselector"
              component={PageAppliedPriceItemSelector}
            />
            <PrivateRoute
              path="/shippingorders/new"
              component={PageEditShippingOrder}
            />
            <PrivateRoute
              path="/shippingorders"
              component={PageShippingOrderList}
            />
            <PrivateRoute
              path="/reports/blockstockin"
              component={PageReportBlocks}
            />
            <PrivateRoute path="/reports/sales" component={PageReportSales} />
            <PrivateRoute
              path="/reports/salesperformance"
              component={PageReportSalesPerf}
            />
            <PrivateRoute
              path="/reports/payment"
              component={PageReportPayment}
            />
            <PrivateRoute
              path="/reports/blockinventory"
              component={PageReportBlockInventory}
            />
            <PrivateRoute
              path="/reports/polishedslabinventory"
              component={PageReportPolishedSlabInventory}
            />
            <PrivateRoute
              path="/reports/manufacturing"
              component={PageReportManufacturing}
            />
            <PrivateRoute path="/reports" component={PageReports} />
            <PrivateRoute
              path="/customers/type/edit/:id"
              component={PageEditCustomerType}
            />
            <PrivateRoute
              path="/customers/grade/edit/:id"
              component={PageEditCustomerGrade}
            />
            <PrivateRoute
              path="/customers/shippingaddress/info/:id"
              component={PageShippingAddressInfo}
            />
            <PrivateRoute
              path="/customers/followuprecord/info/:id"
              component={PageFollowUpRecordInfo}
            />
            <PrivateRoute
              path="/customers/followuprecord/edit/:id"
              component={PageEditFollowUpRecord}
            />
            <PrivateRoute
              path="/customers/depositIncurred/info/:id"
              component={PageDIInfo}
            />
            <PrivateRoute
              path="/customers/type/new"
              component={PageEditCustomerType}
            />
            <PrivateRoute
              path="/customers/grade/new"
              component={PageEditCustomerGrade}
            />
            <PrivateRoute
              path="/customers/info/:id"
              component={PageCustomerInfo}
            />
            <PrivateRoute
              path="/customers/invoiceinfo"
              component={PageInvoiceInfo}
            />
            <PrivateRoute
              path="/customers/editshippingaddress"
              component={PageEditShippingAddress}
            />
            <PrivateRoute
              path="/customers/editinvoiceinfo"
              component={PageEditInvoiceInfo}
            />
            <PrivateRoute
              path="/customers/followuprecord/new"
              component={PageEditFollowUpRecord}
            />
            <PrivateRoute
              path="/customers/depositIncurred/new"
              component={PageEditDI}
            />
            <PrivateRoute
              path="/customers/depositIncurred/edit/:id"
              component={PageEditDI}
            />
            <PrivateRoute
              path="/customers/edit/:id"
              component={PageEditCustomer}
            />
            <PrivateRoute
              path="/customers/type"
              component={PageCustomerTypeList}
            />
            <PrivateRoute
              path="/customers/grade"
              component={PageCustomerGradeList}
            />
            <PrivateRoute path="/customers/new" component={PageEditCustomer} />
            <PrivateRoute path="/customers/list" component={PageCustomerList} />
            <PrivateRoute path="/customers" component={PageCustomerHome} />
            <PrivateRoute path="/paymentorders/new" component={PagePOEdit} />
            <PrivateRoute
              path="/paymentorders/edit/:id"
              component={PagePOEdit}
            />
            <PrivateRoute
              path="/paymentorders/info/:id"
              component={PagePOInfo}
            />
            <PrivateRoute
              path="/paymentorders/itemselector"
              component={PagePOItemSelector}
            />
            <PrivateRoute
              path="/paymentorders/priceitemeditor"
              component={PagePriceItemEditor}
            />
            <PrivateRoute
              path="/paymentorders/appliedpriceitemselector"
              component={PageAppliedPriceItemSelector}
            />
            <PrivateRoute
              path="/paymentorders/approval/:id"
              component={PagePOApproval}
            />
            <PrivateRoute path="/paymentorders" component={PagePOList} />
            <PrivateRoute path="/transferorders/new" component={PageEditTO} />
            <PrivateRoute
              path="/transferorders/edit/:id"
              component={PageEditTO}
            />
            <PrivateRoute
              path="/transferorders/itemselector"
              component={PageTOItemSelector}
            />
            <PrivateRoute
              path="/transferorders/info/:id"
              component={PageTOInfo}
            />
            <PrivateRoute
              path="/transferorders/approval/:id"
              component={PageTOApproval}
            />
            <PrivateRoute path="/transferOrders" component={PageTOList} />
            <PrivateRoute
              path="/untrackedproductsheets/new"
              component={PageUntrackedProductSheetEditor}
            />
            <PrivateRoute
              path="/untrackedproductsheets/edit/:id"
              component={PageUntrackedProductSheetEditor}
            />
            <PrivateRoute
              path="/untrackedproductsheets/untrackedcontenteditor"
              component={PageEditUntrackedContent}
            />
            <PrivateRoute
              path="/untrackedproductsheets/info/:id"
              component={PageUntrackedProductSheetInfo}
            />
            <PrivateRoute
              path="/trimmingrecords/info/:id"
              component={PageTrimmingRecordInfo}
            />
            <PrivateRoute
              path="/trimmingrecords/approval/:id"
              component={PageTrimmingRecordApproval}
            />
            <PrivateRoute
              path="/customerselector"
              component={PageCustomerSelector}
            />
            <PrivateRoute path="/blockselector" component={PageBlockSelector} />
            <PrivateRoute
              path="/trimmingblockselector"
              component={PageTrimmingBlockSelector}
            />
            <PrivateRoute path="/approverview" component={PageApproverView} />
            <PrivateRoute
              path="/slabgradechangerequests/approval/:id"
              component={PageSGCRApproval}
            />
            <PrivateRoute
              path="/slabgradechangerequests/info/:id"
              component={PageSGCRInfo}
            />
            <PrivateRoute
              path="/slabgradechangerequests/itemselector"
              component={PageSGCRItemSelector}
            />
            <PrivateRoute
              path="/slabgradechangerequests/new"
              component={PageSGCREdit}
            />
            <PrivateRoute
              path="/slabgradechangerequests/edit/:id"
              component={PageSGCREdit}
            />
            <PrivateRoute
              path="/slabgradechangerequests"
              component={PageSGCRList}
            />
            <PrivateRoute path="/sysinfo" component={PageSysInfo} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }

  private isDevLoginPage(): boolean {
    return window.location.href.includes("devlogin");
  }

  private isLoggedIn(): boolean {
    return UserInfo.loggedIn();
  }

  private hasAnyPermission(): boolean {
    const permissions = UserInfo.getPermissions();
    return permissions && permissions.length && permissions.length > 0;
  }

  private async ensureLogin() {
    if (!this.isLoggedIn()) {
      const isDDAvailable = dtClientSVC.isJSAPIAvailable();
      const useDevLoginPage =
        settings.environment === "development" && !isDDAvailable;

      if (useDevLoginPage) {
        if (this.isDevLoginPage()) {
          return;
        } else {
          window.location.href = "/devlogin";
          return;
        }
      }

      await this.processLogin();
    }
  }

  private async processLogin() {
    const t = this;
    const s = t.state;

    Toast.show({
      type: "Loading",
      content: "正在登录",
    });

    if (!t.isDDReady) {
      try {
        const ddConfig: IDingtalkConfigDTO = await t.getConfig();
        UserInfo.setDDConfig(ddConfig);
        const corpId: string = await t.configDD(ddConfig);

        const authCode = await t.getDDAuthCode(corpId);
        t.isDDReady = true;
        t.ddAuthCode = authCode;
        await t.login(authCode);
      } catch (err) {
        Toast.hide();
        ErrorHandler.handleErr("登录出错", err);
      }
    } else {
      await t.login(t.ddAuthCode);
    }
  }

  private async getConfig(): Promise<IDingtalkConfigDTO> {
    const s = this.state;

    return new Promise(async (f, r) => {
      await Store.getConfig({
        clientUrl: window.location.href,
        tenantAbbrCode: s.tenantAbbrCode,
      });
      if (Store.hasError) {
        r(Store.errorObj);
      } else {
        f(Store.ddConfig);
      }
    });
  }

  private configDD(ddConfig: IDingtalkConfigDTO): Promise<string> {
    return new Promise((f, r) => {
      const apiList = {
        jsApiList: [
          "runtime.info",
          "runtime.permission.requestOperateAuthCode",
          "device.notification.prompt",
          "device.notification.confirm",
          "device.notification.alert",
          "biz.util.open",
          "biz.user.get",
          "biz.contact.choose",
          "biz.ding.post",
        ],
      };

      const configParam = { ...ddConfig, ...apiList };

      dd.config(configParam);
      dd.error((err) => {
        r(err);
      });
      dd.userid = 0;

      dd.ready(() => {
        f(ddConfig.corpId);
      });
    });
  }

  private erpLogin(authCode: string): Promise<ILoginResultDTO> {
    const s = this.state;
    return new Promise(async (f, r) => {
      await Store.login({
        authCode,
        tenantAbbrCode: s.tenantAbbrCode,
      });
      if (Store.hasError) {
        r(Store.errorObj);
      } else {
        f(Store.loginResult);
      }
    });
  }

  private async login(authCode: string) {
    const t = this;
    try {
      const result: ILoginResultDTO = await t.erpLogin(authCode);
      const ui = result.userInfo;
      const si = result.settings;
      const ti = result.tenantInfo;
      const jwtToken = result.jwtToken;
      UserInfo.setUserInfo(ui);
      UserInfo.setAccessToken(jwtToken);
      Settings.setSettings(si);
      TenantInfo.setTenantInfo(ti);
      Toast.hide();
      // 强制更新界面
      this.setState(this.state);
    } catch (err) {
      Toast.hide();
      ErrorHandler.handleErr("登录出错", err);
    }
  }

  private getDDAuthCode(corpId: string): Promise<string> {
    return new Promise((f, r) => {
      dd.runtime.permission.requestAuthCode({
        corpId,
        onSuccess: (info) => {
          f(info.code);
        },
        onFail(err) {
          r(err);
        },
      });
    });
  }
}

// 设置严格模式;
configure({ enforceActions: "observed" });
ReactDOM.render(<App />, document.getElementById("App"));
