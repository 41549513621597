import { action, observable, runInAction } from "mobx";

import ClientFactory from "../../../app/WebAPIClientFactory";
import { ISalesPerformanceReportDTO } from "../../../app/WebAPIClients";
import Util from "../../../components/Util";
import Base from "../../../store/base";

class Store extends Base {
  @observable public reportData: ISalesPerformanceReportDTO = null;
  @observable public activeTabIndex: string = "0";

  @action
  public changeActiveTab = (activeTabIndex: string) => {
    const t = this;
    if (!Util.isDefinedAndNotNull(activeTabIndex)) {
      return;
    }

    if (t.activeTabIndex === activeTabIndex) {
      return;
    }

    runInAction(() => {
      this.activeTabIndex = activeTabIndex;
    });
  };

  @action
  public async loadData({
    startDate,
    endDate,
  }: {
    startDate: Date;
    endDate: Date;
  }) {
    const t = this;
    const client = ClientFactory.getReportClient();
    const callAPI = async () => {
      const reportData = await client.getSalesPerformanceReport(
        startDate,
        endDate,
      );
      runInAction(() => {
        this.reportData = reportData;
      });
    };
    await this.callAPIFunForLoadData(callAPI);
  }
}

export default new Store();
